<template>
  <div v-if="reportModel" class="charts">
    <div v-for="(item, index) of chartModel?.liteLineChartsArray" :key="item.title">
      <line-chart-pressure v-if="index == 0" :reportModel="reportModel"
        :lineChartPressureArray="chartModel.lineChartPressureArray" :timeBarArray="timeBarArray" :model="item"
        :chartWidth="chartWidth" :timeSelectBarWidth="timeSelectBarWidth" :isFullFunctional="isFullFunctional"
        :inputSelectedIndex="selectedIndex" :inputExtremeTimeStampArray="extremeTimeStampArray"
        :chartsDisplayInfoArray="chartsDisplayInfoArray" :printHeaderInfo="printHeaderInfo"></line-chart-pressure>
      <line-chart v-else :timeBarArray="timeBarArray" :model="item" :chartWidth="chartWidth"
        :timeSelectBarWidth="timeSelectBarWidth" :isFullFunctional="isFullFunctional"
        :inputSelectedIndex="selectedIndex" :inputExtremeTimeStampArray="extremeTimeStampArray"
        :chartsDisplayInfoArray="chartsDisplayInfoArray" :printHeaderInfo="printHeaderInfo"></line-chart>
      <bar-chart-event v-if="index == 1" :timeBarArray="timeBarArray" :barChartDataArray="chartModel.barChartEventArray"
        :chartWidth="chartWidth" :timeSelectBarWidth="timeSelectBarWidth" :isFullFunctional="isFullFunctional"
        :inputSelectedIndex="selectedIndex" :inputExtremeTimeStampArray="extremeTimeStampArray"
        :chartsDisplayInfoArray="chartsDisplayInfoArray" :printHeaderInfo="printHeaderInfo" />
    </div>
  </div>
</template>

<script>
import LineChartPressure from "@c/report/charts/chart/single-chart/line-chart-pressure/line-chart-pressure.vue";
import LineChart from "@c/report/charts/chart/single-chart/line-chart.vue";
import BarChartEvent from "@c/report/charts/chart/single-chart/bar-chart-event.vue";
import { ChartModel } from "../../../assets/js/model/chart-model.js";
import { DateTool } from "@js/date-tool.js";

export default {
  components: {
    LineChartPressure,
    LineChart,
    BarChartEvent,
  },

  props: {
    chartWidth: Number,
    timeSelectBarWidth: Number,
    inputReportModel: Object,
    chartsDisplayInfoArray: Array,
    printHeaderInfo: Object,
  },

  data() {
    return {
      isFullFunctional: false,
      reportModel: undefined,
      chartModel: undefined,
      timeBarArray: [],
      selectedIndex: 10,
      extremeTimeStampArray: [],
    };
  },

  created() {
    this.reportModel = this.inputReportModel
    this.getChartData();
  },

  watch: {
    inputReportModel(value) {
      this.reportModel = value
      this.getChartData()
    }
  },

  methods: {
    //method
    async getChartData() {
      try {
        const params = {
          reportId: this.reportModel.reportID,
          showBloodOxygen: false,
          showHeartRate: false
        };
        const data = await this.$api.getSingleReportChartData(params);
        if (data) {
          this.chartModel = new ChartModel(data);
        } else {
          throw "暂无图表数据"
        }
        //初始数据
        if (!this.timeBarArray.length) {
          //时间轴数据
          this.timeBarArray = this.chartModel.timeArray.map((item) => {
            return {
              timeStamp: item,
              timeStr: DateTool.milliTimestampToStr(item, "HH:mm:ss"),
            };
          });
          //时间轴端点
          this.extremeTimeStampArray = [
            this.timeBarArray[0].timeStamp,
            this.timeBarArray[this.timeBarArray.length - 1].timeStamp,
          ];
        }
      } catch (error) {
        this.$toast.showRed(error);
        this.chartModel = null
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 50px;
}
</style>