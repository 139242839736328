<template>
  <div class="statistics-charts">
    <statistics-chart v-if="!isLite" title="使用时长（小时）" :max="24" :dayArray="dayArray"
      :chartDataArray="useHourArray"></statistics-chart>
    <statistics-chart title="90%压力趋势（cmH2O）" :dayArray="dayArray" :chartDataArray="p90Array"></statistics-chart>
    <statistics-chart title="AHI" :max="chartData.maxAHI" :dayArray="dayArray"
      :chartDataArray="ahiDataArray"></statistics-chart>
    <statistics-chart title="CSA/CSR" :max="5" :dayArray="dayArray" :chartDataArray="csaDataArray"></statistics-chart>
    <statistics-chart v-if="!isLite" title="PB" :max="5" :dayArray="dayArray"
      :chartDataArray="PBDataArray"></statistics-chart>
    <statistics-chart v-if="!isLite" title="湿化器设置" :dayArray="dayArray"
      :chartDataArray="humidifyLevelDataArray"></statistics-chart>
  </div>
</template>

<script>
import StatisticsChart from "@c/report/charts/chart/statistics-chart/statistics-chart.vue";
export default {
  components: {
    StatisticsChart,
  },

  props: {
    chartData: Object,

    isLite: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      dayArray: [],

      useHourArray: [],

      p90Array: [],

      ahiDataArray: [],

      csaDataArray: [],

      PBDataArray: [],

      humidifyLevelDataArray: [],
    };
  },

  mounted() {
    this.setupData()
  },

  watch: {
    chartData() {
      this.setupData()
    },
  },

  methods: {
    setupData() {
      this.dayArray = this.chartData.dayArray
      this.useHourArray = [
        {
          name: "大于4小时",
          type: "bar",
          color: "#58C93D",
          stack: null,
          dataArray: this.chartData.useHourAbove4Array,
        },
        {
          name: "小于4小时",
          type: "bar",
          color: "#FF4E4E",
          stack: null,
          dataArray: this.chartData.useHourUnder4Array,
        },
      ]
      this.p90Array = [
        {
          name: "90%压力趋势",
          type: "line",
          color: "#000000",
          stack: null,
          dataArray: this.chartData.pressure90Array,
        },
        {
          name: "最大压力设定",
          type: "line",
          color: "#FFD188",
          stack: null,
          dataArray: this.chartData.pressureMaxArray,
        },
        {
          name: "最小压力设定",
          type: "line",
          color: "#FC8CEF",
          stack: null,
          dataArray: this.chartData.pressureMinArray,
        },
      ]
      this.ahiDataArray = [
        {
          name: "AI",
          type: "bar",
          color: "#8CA1FC",
          stack: "ahi",
          dataArray: this.chartData.aiArray,
        },
        {
          name: "HI",
          type: "bar",
          color: "#88C7FD",
          stack: "ahi",
          dataArray: this.chartData.hiArray,
        },
      ]
      this.csaDataArray = [
        {
          name: "CSA",
          type: "bar",
          color: "#F8B3AE",
          stack: "csa",
          dataArray: this.chartData.csaArray,
        },
        {
          name: "CSR",
          type: "bar",
          color: "#F8E1AE",
          stack: "csa",
          dataArray: this.chartData.csrArray,
        },
      ]
      this.PBDataArray = [
        {
          name: "PB",
          type: "bar",
          color: "#E295FC",
          stack: null,
          dataArray: this.chartData.pbArray,
        },
      ]
      this.humidifyLevelDataArray = [
        {
          name: "湿化等级",
          type: "line",
          color: "#000000",
          stack: null,
          dataArray: this.chartData.humidifyLevelArray,
        },
      ]
    }
  }
};
</script>

<style lang="scss" scoped>
.statistics-charts {
  margin-top: 20px;

  >.statistics-chart{
    margin-bottom: 20px;
  }
}
</style>