<template>
  <div v-if="statisticsReportModel" class="report">
    <div class="report-container">
      <div class="report-container-top">
        <div class="report-container-top-top">
          <p class="report-container-top-top-title">
            使用报告（{{ statisticsDayCount }}天）
          </p>
          <barcode v-if="statisticsReportModel.patientNum" :patientNum="statisticsReportModel.patientNum" />
        </div>
        <div class="report-container-top-bottom">
          <div class="report-container-top-bottom-left">
            <title-info-label title="设备序列号" :info="statisticsReportModel.deviceSN" />
            <title-info-label title="设备型号" :info="statisticsReportModel.deviceModel" />
          </div>
          <title-info-label title="统计时间" :info="statisticsReportModel.statisticsRange" />
        </div>
      </div>
      <report-info-block title="患者资料" :dataArray="statisticsReportModel.basicInfoArray" :widthPercent="100">
      </report-info-block>
      <report-info-block title="依从性分析" :dataArray="statisticsReportModel.dependencyArray" :widthPercent="100">
      </report-info-block>
      <div class="report-container-configuration-info" v-for="(item, index) of statisticsReportModel.paraArray"
        :key="index">
        <p class="report-container-configuration-info-title">
          {{ item.title }}
        </p>
        <report-info-block title="设置信息" :dataArray="item.paraArray" :widthPercent="100"></report-info-block>
      </div>
      <use-time-chart :useTimeArray="statisticsReportModel.useTimeArray"></use-time-chart>
      <statistics-charts :chartData="statisticsReportModel.chartData"></statistics-charts>
      <div class="report-container-report-picker">
        <p class="report-container-report-picker-title">每日详细信息</p>
        <div class="report-container-report-picker-right">
          <p class="report-container-report-picker-right-title">请选择</p>
          <list-picker class="report-container-report-picker-right-picker"
            :arrowIcon="require('@imgs/report-arrow-down.png')" :inputInfoArray="singleReportDateArray"
            :inputSelectedInfo="singleReportDateArray[inputSelectedIndex]" @onSelectedIndexChanged="onReportPicked">
          </list-picker>
        </div>
      </div>
      <statistics-single-charts class="report-container-single-charts" v-if="singleReportModel"
        :inputReportModel="singleReportModel" :chartsDisplayInfoArray="chartsDisplayInfoArray"
        :printHeaderInfo="printHeaderInfo"></statistics-single-charts>
    </div>
  </div>
</template>

<script>
import Barcode from "@c/report/barcode.vue";
import TitleInfoLabel from "@c/report/title-info-label.vue";
import ReportInfoBlock from "@c/report/report-info-block.vue";
import ListPicker from "@c/common/list-picker/list-picker.vue";
import StatisticsCharts from "@c/report/charts/statistics-charts.vue";
import StatisticsSingleCharts from "@c/report/charts/statistics-single-charts.vue";
import UseTimeChart from "@c/report/charts/chart/statistics-chart/use-time-chart/use-time-chart.vue";
import { StatisticsReportModel } from "@js/model/statistics-report-model.js";
import { ReportModel } from "@js/model/report-model.js";
export default {
  components: {
    Barcode,
    TitleInfoLabel,
    ReportInfoBlock,
    ListPicker,
    StatisticsCharts,
    StatisticsSingleCharts,
    UseTimeChart,
  },

  props: {
    reportTypeInitialInfo: Object,
    selectedInfo: Object,
  },

  data() {
    return {
      statisticsReportModel: undefined,
      singleReportModel: undefined,
      inputSelectedIndex: 0,
      chartsDisplayInfoArray: [
        {
          name: "压力",
          isChecked: true,
          needBreak: [],
        },
        {
          name: "事件",
          isChecked: true,
          needBreak: [],
        },
        {
          name: "漏气",
          isChecked: true,
          needBreak: [],
        },
      ],
    };
  },

  computed: {
    //依赖selectedInfo
    statisticsDayCount() {
      const statisticsDurationSeconds =
        this.selectedInfo.reportDuration[1] -
        this.selectedInfo.reportDuration[0];
      const statisticsDurationDay = Math.ceil(statisticsDurationSeconds / (3600 * 24));
      return statisticsDurationDay;
    },

    //依赖singleReportModel
    printHeaderInfo() {
      return {
        patientName: this.singleReportModel?.model.user.name,
        deviceModel: this.singleReportModel?.deviceModel,
        deviceSN: this.singleReportModel?.deviceSN,
        statisticsTime: this.singleReportModel?.deviceStatisticsDuration,
      };
    },

    singleReportDateArray() {
      return this.statisticsReportModel.reportArray.map((item) => {
        return item.createDate;
      });
    },
  },

  watch: {
    selectedInfo() {
      this.reloadData();
    },
  },

  async created() {
    this.reloadData();
  },

  methods: {
    async onReportPicked(index) {
      this.inputSelectedIndex = index;
      //请求选中的单日报告
      const selectedSingleReportId =
        this.statisticsReportModel?.reportArray[index].id;
      this.singleReportModel = await this.getSingleReportDetail(
        selectedSingleReportId
      );
    },

    async reloadData() {
      //请求多日报告
      this.statisticsReportModel = await this.getReportDetail();
      //请求最新单日报告
      this.inputSelectedIndex = 0
      const latestSingleReportId = this.statisticsReportModel?.reportArray[0].id;
      if (latestSingleReportId) {
        this.singleReportModel = await this.getSingleReportDetail(
          latestSingleReportId
        );
      }
    },

    async getReportDetail() {
      try {
        this.statisticsReportModel = undefined;
        const params = {
          hospitalId: this.reportTypeInitialInfo.hospitalId,
          start: this.selectedInfo.reportDuration[0],
          end: this.selectedInfo.reportDuration[1],
          uid: this.reportTypeInitialInfo.uid,
        };
        const data = await this.$api.getReportStatisticsDetail(params);
        return new StatisticsReportModel(data);
      } catch (error) {
        this.$emit("onNoReport");
        return
      }
    },

    async getSingleReportDetail(singleReportId) {
      try {
        const params = {
          id: singleReportId,
        };
        const data = await this.$api.getSingleReportDetail(params);
        return new ReportModel(data);
      } catch (error) {
        this.$toast.showRed(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.report {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;

  &-container {
    width: 900px;
    padding-top: 100px;
    display: flex;
    flex-direction: column;

    &-top {
      background-color: #f7f7f7;
      box-sizing: border-box;
      padding-top: 33px;
      padding-bottom: 20px;
      width: 100%;
      height: 171px;

      &-top {
        box-sizing: border-box;
        padding-left: 20px;
        padding-right: 10px;
        height: 76px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-title {
          color: black;
          font-size: 48px;
          font-weight: bold;
          line-height: 33px;
        }
      }

      &-bottom {
        margin-top: 21px;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        padding-left: 20px;
        padding-right: 20px;

        &-left {
          display: flex;

          :first-child {
            margin-right: 27px;
          }
        }
      }
    }

    &-configuration-info {
      margin: 20px 0px;

      &-title {
        color: black;
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 20px;
      }
    }

    &-report-picker {
      height: 62px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-title {
        color: black;
        font-size: 16px;
        font-weight: 500;
      }

      &-right {
        display: flex;
        align-items: center;

        &-title {
          color: #666666;
          font-size: 16px;
          margin-right: 20px;
        }

        &-picker {
          width: 272px;
          height: 32px;
        }
      }
    }

    &-single-charts {
      margin-bottom: 100px;
    }
  }
}
</style>